exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datahanke-js": () => import("./../../../src/pages/datahanke.js" /* webpackChunkName: "component---src-pages-datahanke-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-icare-js": () => import("./../../../src/pages/icare.js" /* webpackChunkName: "component---src-pages-icare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kauppa-js": () => import("./../../../src/pages/kauppa.js" /* webpackChunkName: "component---src-pages-kauppa-js" */),
  "component---src-pages-kiitos-js": () => import("./../../../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-meta-js": () => import("./../../../src/pages/meta.js" /* webpackChunkName: "component---src-pages-meta-js" */),
  "component---src-pages-teollisuus-js": () => import("./../../../src/pages/teollisuus.js" /* webpackChunkName: "component---src-pages-teollisuus-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

