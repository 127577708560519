import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import FI from './locale/fi.json'
import EN from './locale/en.json'

const fiKey = 'fi'

const resources = {
  [fiKey]: {
    translation: FI
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: fiKey,
    resources,
    fallbackLng: fiKey,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;